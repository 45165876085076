/* eslint-disable no-console */
import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import createPersistedState from "vuex-persistedstate";
import lodash from "lodash";

Vue.use(Vuex);

// eslint-disable-next-line no-unused-vars
function snapshotToArray(snapshot) {
	var returnArray = [];

	snapshot.forEach((childSnapshot) => {
		var item = childSnapshot.data();
		//var item = {}; //We only really need the id
		item.id = childSnapshot.id;

		returnArray.push(item);
	});

	return returnArray;
}

const store = new Vuex.Store({
	modules,
	state: {
		currentUser: null,
		selectedUser: {},
		selectedOnboardUser: {},
		firebaseUser: null,
		roles: [],
		selectedLicense: {},
		selectedNotification: {},
		selectedCertification: {},
		selectedEmployment: {},
		selectedReference: {},
		selectedChecklist: {},
		selectedUserChecklist: {},
		selectedAcademicInformation: {},
		selectedForm: {},
		selectedFacility: {},
		selectedJob: {},
		selectedJobFacility: {},
		requestProfile: {},
		assignJob: {},
		selectedJobDescription: {},
		selectedMedical: {},
		selectedJobStatus: {},
		removeJournal: {},
	},
	plugins: [createPersistedState()],
	actions: {
		clearData({ commit }) {
			// eslint-disable-next-line no-console
			//console.log('CLEAR');
			commit("setCurrentUser", null);
			commit("setSelectedUser", {});
			commit("setSelectedOnboardUser", {});
			commit("setFirebaseUser", null);
			commit("setRoles", []);
			commit("setSelectedLicense", {});
			commit("setSelectedNotification", {});
			commit("setSelectedCertification", {});
			commit("setSelectedEmployment", {});
			commit("setSelectedReference", {});
			commit("setSelectedChecklist", {});
			commit("setSelectedMedical", {});
			commit("setSelectedUserChecklist", {});
			commit("setSelectedAcademicInformation", {});
			commit("setSelectedForm", {});
			commit("setSelectedFacility", {});
			commit("setSelectedFacilityUser", {});
			commit("setSelectedJob", {});
			commit("setSelectedJobFacility", {});
			commit("requestProfile", {});
			commit("assignUserJob", {});
			commit("setSelectedJobDescription", {});
			commit("setSelectedJobStatus", {});
			commit("removeJournal", {});
			commit("setSelectedCheckIn", {});
			commit("setSelectedInvoice", {});
		},
	},
	getters: {
		isAdmin: (state) => {
			const roles = state.roles;
			const isAdmin = roles && roles.includes("admin");
			return isAdmin;
		},
		isHp: (state) => {
			const user = state.currentUser;
			const type = user.type || "";
			return type === "health professional";
		},
		isFacility: (state) => {
			const user = state.currentUser;
			const type = user.type || "";
			return (
				type === "facility admin" || type === "facility timesheet approver"
			);
		},
	},
	mutations: {
		setCurrentUser(state, val) {
			state.currentUser = val;
		},
		setSelectedUser(state, val) {
			state.selectedUser = val;
		},
		setSelectedOnboardUser(state, val) {
			// eslint-disable-next-line no-console
			//console.log(val.id);
			let clone = lodash.cloneDeep(val);
			if (clone.id == null) {
				clone.id = val.id;
			}
			// eslint-disable-next-line no-console
			//console.log(clone);
			state.selectedOnboardUser = clone;
		},
		setFirebaseUser(state, val) {
			state.firebaseUser = val;
		},
		setRoles(state, val) {
			state.roles = val;
		},
		setSelectedLicense(state, val) {
			state.selectedLicense = val;
		},
		setSelectedNotification(state, val) {
			state.selectedNotification = val;
		},
		setSelectedCertification(state, val) {
			state.selectedCertification = val;
		},
		setSelectedMedical(state, val) {
			state.selectedMedical = val;
		},
		setSelectedEmployment(state, val) {
			state.selectedEmployment = val;
		},
		setSelectedReference(state, val) {
			state.selectedReference = val;
		},
		setSelectedAcademicInformation(state, val) {
			state.selectedAcademicInformation = val;
		},
		setSelectedChecklist(state, val) {
			state.selectedChecklist = val;
		},
		setSelectedUserChecklist(state, val) {
			state.selectedUserChecklist = val;
		},
		setSelectedForm(state, val) {
			//console.log(val);
			state.selectedForm = val;
		},
		setSelectedFacility(state, val) {
			//console.log(val);
			state.selectedFacility = val;
		},
		setSelectedFacilityUser(state, val) {
			//console.log(val);
			state.selectedFacilityUser = val;
		},
		setSelectedJob(state, val) {
			//console.log(val);
			state.selectedJob = val;
		},
		setSelectedJobFacility(state, val) {
			//console.log(val);
			state.selectedJobFacility = val;
		},
		requestProfile(state, val) {
			//console.log(val);
			state.requestProfile = val;
		},
		assignUserJob(state, val) {
			//console.log(val);
			state.assignJob = val;
		},
		setSelectedJobDescription(state, val) {
			//console.log(val);
			state.selectedJobDescription = val;
		},
		removeJournal(state, val) {
			//console.log(val);
			state.removeJournal = val;
		},
		setSelectedJobStatus(state, val) {
			//console.log(val);
			state.selectedJobStatus = val;
		},
		setSelectedCheckIn(state, val) {
			//console.log(val);
			state.selectedCheckIn = val;
		},
		setSelectedInvoice(state, val) {
			//console.log(val);
			state.selectedInvoice = val;
		},
	}, //,
	//strict: process.env.NODE_ENV !== 'production'
});

export default store;
