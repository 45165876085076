/* eslint-disable no-console */
import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

import store from "@/state/store";
import routes from "./routes";

Vue.config.errorHandler = (error, vm, info) => {
	console.error("An error occurred:", error);
	console.log("Vue instance:", vm);
	console.log("Error info:", info);
	// Add additional error handling logic here, such as reporting the error to a monitoring service
};

Vue.use(VueRouter);
Vue.use(VueMeta, {
	// The component option name that vue-meta looks for meta info on.
	keyName: "page",
});

const router = new VueRouter({
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: "history",
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
	try {
		if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
			//console.log(routeTo);
			//console.log(routeFrom);
			// Check if auth is required on this route
			// (including nested routes).
			const authRequired = routeTo.matched.some(
				(route) => route.meta.authRequired
			);

			// If auth isn't required for the route, just continue.
			if (!authRequired) return next();

			//console.log("route check index");
			//console.log(store.getters["auth/loggedIn"]);

			// Check if the user is being redirected from the current route
			if (routeTo.name === "logout" && routeFrom.name === "login") {
				//Allow the redirect to complete
				return next();
			}

			// If auth is required and the user is logged in...
			if (store.getters["auth/loggedIn"]) {
				// Validate the local user token...
				return store.dispatch("auth/validate").then((validUser) => {
					// Then continue if the token still represents a valid user,
					// otherwise redirect to login.
					validUser
						? next()
						: next({
								name: "login",
								query: { redirectFrom: routeTo.fullPath },
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  });
				});
			}

			// If auth is required and the user is NOT currently logged in,
			// redirect to login.
			next({ name: "login", query: { redirectFrom: routeTo.fullPath } });

			// eslint-disable-next-line no-unused-vars
			// eslint-disable-next-line no-inner-declarations
		} else {
			const publicPages = ["/login", "/register", "/forgot-password"];
			const authpage = !publicPages.includes(routeTo.path);
			const loggeduser = localStorage.getItem("user");

			if (authpage && !loggeduser) {
				return next("/login");
			}

			next();
		}
	} catch (error) {
		console.log(error);
		return;
	}
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
	// Create a `beforeResolve` hook, which fires whenever
	// `beforeRouteEnter` and `beforeRouteUpdate` would. This
	// allows us to ensure data is fetched even when params change,
	// but the resolved route does not. We put it in `meta` to
	// indicate that it's a hook we created, rather than part of
	// Vue Router (yet?).
	try {
		// eslint-disable-next-line no-console
		// For each matched route...
		for (const route of routeTo.matched) {
			await new Promise((resolve, reject) => {
				// If a `beforeResolve` hook is defined, call it with
				// the same arguments as the `beforeEnter` hook.
				if (route.meta && route.meta.beforeResolve) {
					route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
						//console.log(routeTo);
						// If the user chose to redirect...
						if (args.length) {
							// If redirecting to the same route we're coming from...
							// Complete the redirect.
							//console.log(args);
							next(...args);
							reject(new Error("Redirected"));
						} else {
							//console.log('R2');
							resolve();
						}
					});
				} else {
					// Otherwise, continue resolving the route.
					//console.log('R3');
					resolve();
				}
			});
		}
		// If a `beforeResolve` hook chose to redirect, just return.
	} catch (error) {
		console.log(error);
		return;
	}

	// If we reach this point, continue resolving the route.
	next();
});

export default router;
